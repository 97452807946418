import { React } from "react";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  // useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

import {
  HamburgerIcon,
  AddIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
// import { FcGoogle } from "react-icons/fc";
// import { FaEthereum } from "react-icons/fa";

// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w-f.png";
// import background from "./images/mg-bckg-06c-1280w-f.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

function Header1() {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Box>
        <Flex
          // bg={useColorModeValue("gray.900", "gray.800")}
          color={useColorModeValue("gray.900", "gray.200")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          align={"center"}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{ base: "flex", md: "none" }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>

          <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
            {/* <Avatar
              ml={"2"}
              size={"xl"}
              src={
                // " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
                // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                // " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
                " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
                // " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "

              }
            /> */}
            <Avatar
              ml={"2"}
              size={"2xl"}
              src={
                // " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
                // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                // " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
                // " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
                // " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
                // " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
                " https://bafybeiakbeu5a6g4jhrvurbamc75q6d2yoo23ihjguk7va4bdnvdfa5bhe.ipfs.infura-ipfs.io/ "
                // " https://bafybeig6ya4h6ozshcm5tcad5i2aklumdhokzobs4l3xjq7q4gwvg5idcu.ipfs.infura-ipfs.io/ "
                // " https://bafybeicrnln2gm2a5ivbeeepdupdeg33gzzboxw3smo3ssgtx2xoxrrfoe.ipfs.infura-ipfs.io/ "
                // " https://bafybeicjsmb535kxrkwdsfqm7oruiionzf2yvhwl2fxhwvf2j756zkfwcq.ipfs.infura-ipfs.io/ "
                // " https://bafybeidqzoh3flsxoyy264653ctvvero6k52eyrlp6v2qhpk57dacfktb4.ipfs.infura-ipfs.io/ "
                // " https://bafybeifqq4uwoiw2dc5cqimpic3wjp5zhm6uyyuxmspuvvh4kvu74dlyta.ipfs.infura-ipfs.io/ "
                

              }
            />
            <Box flexShrink={0}>
              <Image
                ml="15px"
                mt="20px"
                // borderRadius="lg"
                width={{ lg: 200, md: 40, sm: 100 }}
                src=
                // " https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io "
                // " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
                // " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
                " https://bafybeigpvim4tjkw6mwaddpymft46grbswwzf3tkmskkznkfdus3udtgum.ipfs.infura-ipfs.io/ "
                // " https://bafybeia2thakmxvj2fpiaaloz733otuiewttonur4opufycnltcqnc4m3m.ipfs.infura-ipfs.io/ "
                // " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
               
                                
                alt=" MetaCollab "
              />
            </Box>
          </Flex>
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={2}
          >

<Menu fontFamily="Exo 2, san-serif">
              <MenuButton as={Button} colorScheme="purple" variant="solid">
                Doer Profiles
              </MenuButton>
              <MenuList color="gray.200">
                <MenuGroup title=" Profile">
                  <MenuItem> My Account </MenuItem>
                  <MenuItem> Payments </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title=" Help">
                  <MenuItem> Docs </MenuItem>
                  <MenuItem> FAQ </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
            <br />

            <Menu>
              <MenuButton as={Button} colorScheme="blue" variant="solid">
                Funder Profiles
              </MenuButton>
              <MenuList color="gray.200">
                <MenuGroup title=" Profile">
                  <MenuItem> My Account </MenuItem>
                  <MenuItem> Payments </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title=" Help">
                  <MenuItem> Docs </MenuItem>
                  <MenuItem> FAQ </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
            <br />
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} 
              size={"md"}
              mr={4}
              colorScheme="green" 
              variant="solid" 
              leftIcon={<AddIcon />}
              >
                Web3Skills MetaCollab
              </MenuButton>
              <MenuList minWidth="240px" color="gray.200">
                <MenuOptionGroup defaultValue="asc" title=" Order" type="radio">
                  <MenuItemOption value="asc"> Ascending </MenuItemOption>
                  <MenuItemOption value="desc"> Descending </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup title=" Country" type="checkbox">
                  <MenuItemOption value="email"> Email </MenuItemOption>
                  <MenuItemOption value="phone"> Phone </MenuItemOption>
                  <MenuItemOption value="country"> Country </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>

            <Button
              display={{ base: "none", md: "inline-flex" }}
              size={"md"}
              fontSize={"lg"}
              fontWeight={600}
              color={"gray.200"}
              bg={"green.500"}
              href={" # "}
              _hover={{
                bg: "green.800",
              }}
            >
              Web3 Connect
            </Button>


            <Menu>
              <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar
                  size={"lg"}
                  src={
                    // " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
                    // " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
                    // " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeianeok6ic7irwtxgrblulbairprwpxo2bk4brwftvqcywcwb5k6ou.ipfs.infura-ipfs.io/ "
                    " https://bafybeidockxkzred5eiymuwbgtxueln4d42sy7si2oqtjhyvo2gwpfkeim.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiepkjprcey6awxfvhcsls6s5ghqkaq5zmfziu4s7mxvnqcughpzvm.ipfs.infura-ipfs.io/ "
                  }
                />
              </MenuButton>


{/* <MenuButton
                as={Button}
                rounded={"full"}
                variant={"link"}
                cursor={"pointer"}
                minW={0}
              >
                <Avatar
                  size={"lg"}
                  src={
                    // " https://bafkreihpob65rfthwbxx3xvuk6rj4r564qqx6irpjcuktuojkny3nglsqu.ipfs.infura-ipfs.io/ "
                    // " https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/ "
                    // " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/ "
                    // " https://bafybeihhmhgalqytd5ntpysjqwlgo56wvvb7r6rcuuj2x3r6l3aqnjf25m.ipfs.infura-ipfs.io/ "
                    // " https://bafybeid2lgmvtnxcz7ivszvxne5jfabpcy62ff6k5t3jracs46gwpvag2m.ipfs.infura-ipfs.io/ "
                    // " https://bafybeiawt6vzzsvjjfpzsggjkt5s2gtapn5lg3gl67xxdis25kzv7frkk4.ipfs.infura-ipfs.io/ "
                    // " https://bafybeianeok6ic7irwtxgrblulbairprwpxo2bk4brwftvqcywcwb5k6ou.ipfs.infura-ipfs.io/ "
                    // " https://bafybeidockxkzred5eiymuwbgtxueln4d42sy7si2oqtjhyvo2gwpfkeim.ipfs.infura-ipfs.io/ "
                    " https://bafybeiepkjprcey6awxfvhcsls6s5ghqkaq5zmfziu4s7mxvnqcughpzvm.ipfs.infura-ipfs.io/ "
                    
                    

                  }
                />
              </MenuButton> */}
              
              <MenuList color="gray.200">
                <MenuItem> Link 1 </MenuItem>
                <MenuItem> Link 2 </MenuItem>
                <MenuDivider />
                <MenuItem> Link 3 </MenuItem>
              </MenuList>
            </Menu>

            <Icon as={HamburgerIcon} w={8} h={8} color="blue.500" />
          </Stack>
        </Flex>


        <Box mt={{ base: 4, md: 2 }} ml={{ md: 6 }}>

          <HStack
            mt="10px"
            align="flex"
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
          >
            <DesktopNav />
          </HStack>
        </Box>
      </Box>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </div>
  );
}
const DesktopNav = () => {
  // const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("blue.200", "gray.600");
  const popoverContentBgColor = useColorModeValue("gray.200", "gray.800");
  return (
    <Stack direction={"row"} spacing={6}>
      {NAV_ITEMS.map((navItem) => (
        <Box mb={"24pt"} key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                href={navItem.href ?? " # "}
                fontSize={"lg"}
                fontWeight={700}
                color={"gray.200"}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>
            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};
const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Link
      href={href}
      role={" group "}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("pink.50", "gray.500") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "pink.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon as={ChevronRightIcon} w={5} h={5} color="pink.400" />
        </Flex>
      </Stack>
    </Link>
  );
};
const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("gray.200", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};
const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? " # "}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>
      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}
const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Explore MetaCollab ",
    children: [
      {
        label: "Explore MetaCollab Developer Tools ",
        subLabel: "Choose your own Web3 adventure",
        href: " # ",
      },
      {
        label: "MetaCollab News ",
        subLabel: "Up-and-coming Developers choose Web3",
        href: " # ",
      },
    ],
  },
  {
    label: "MetaCollab Gigs ",
    children: [
      {
        label: "MetaCollab Gig Board ",
        subLabel: "Find your Web3 path",
        href: " # ",
      },
      {
        label: "MetaCollab Developer Gigs ",
        subLabel: "Exclusive Web3 Resources",
        href: " # ",
      },
    ],
  },
  {
    label: "Learn MetaCollab ",
    href: " # ",
  },
  {
    label: "Earn with MetaCollab ",
    href: " # ",
  },
];
export default Header1;
